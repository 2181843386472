import { Controller } from "@hotwired/stimulus"

class Showmore extends Controller {
  static targets = ["container", "indicator", "contents", "button"]
  static classOpen = "-open"
  static classOpened = "-opened"

  static values = {
    open: String,
    closed: String,
  }

  initialize() {
    this.isOpen = false
  }

  connect() {
    if (this.containerTarget.classList.contains(Showmore.classOpen)) {
      this.open()
    }
  }

  open() {
    if (this.isOpen) {
      return
    }
    if (this.hasIndicatorTarget) {
      this.indicatorTarget.classList.add(Showmore.classOpen)

      if (this.openValue) {
        this.indicatorTarget.setAttribute("aria-label", this.openValue)
      }
    }
    this.containerTarget.classList.add(Showmore.classOpen)
    this.containerTarget.style.maxHeight = this.contentsTarget.scrollHeight + "px"
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add(Showmore.classOpened)
    }
    this.isOpen = true
  }

  resize() {
    if (this.isOpen) {
      this.containerTarget.style.maxHeight = this.contentsTarget.scrollHeight + "px"
    }
  }

  close() {
    if (!this.isOpen) {
      return
    }
    if (this.hasIndicatorTarget) {
      this.indicatorTarget.classList.remove(Showmore.classOpen)

      if (this.closedValue) {
        this.indicatorTarget.setAttribute("aria-label", this.closedValue)
      }
    }

    this.containerTarget.classList.remove(Showmore.classOpen)
    this.containerTarget.style.maxHeight = "0"
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove(Showmore.classOpened)
    }
    this.isOpen = false
  }

  toggle() {
    this.containerTarget.offsetHeight === 0 ? this.open() : this.close()
  }

  noop() {}
}

export { Showmore }
