import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"
import { isMobile } from "../../../ui/static_src/ui/utils/breakpoints"

const HIGHLIGHTED_CLASS = "-highlighted"
const DISABLED_CLASS = "-disabled"
const SCROLLING_INTO_CLASS = "-scrolling-into"

class TranscriptVideo extends Controller {
  static targets = ["chapter", "emptyText", "paragraph", "speaker", "video"]
  static attributeStartTime = "data-transcript-video-start-timecode-value"
  static attributeEndTime = "data-transcript-video-end-timecode-value"
  static classActive = "-active"
  static classHighlighted = "-highlighted"
  static classParagraph = ".transcript-paragraph__text"

  format() {
    const targetGroups = [
      { targets: this.paragraphTargets, isParagraph: true },
      { targets: this.chapterTargets, isParagraph: false },
    ]

    targetGroups.forEach(group => {
      const target = this.getTargetElement(group.targets, this.videoTarget.currentTime)
      const current = this.getCurrentElement(group.targets, TranscriptVideo.classActive)

      if (!target) return

      if (current && current.getAttribute(TranscriptVideo.attributeStartTime) !== target.getAttribute(TranscriptVideo.attributeStartTime)) {
        current.classList.remove(TranscriptVideo.classActive)
      }
      target.classList.add(TranscriptVideo.classActive)

      if (group.isParagraph) {
        if (current) {
          const currentSpeaker = this.getSpeaker(current)
          currentSpeaker.classList.remove(TranscriptVideo.classActive)
        }
        // apply format
        const speaker = this.getSpeaker(target)
        speaker.classList.add(TranscriptVideo.classActive)
      }
    })
  }

  getTargetElement(targets, timecode) {
    return targets.find((element) => parseFloat(element.getAttribute(TranscriptVideo.attributeStartTime)) <= timecode && parseFloat(element.getAttribute(TranscriptVideo.attributeEndTime)) > timecode)
  }

  getCurrentElement(targets, cssClass) {
    return targets.find((element) => element.classList.contains(cssClass))
  }

  getSpeaker(element) {
    let speaker
    let sibling = element.previousElementSibling
    while (sibling) {
      if (sibling.matches("span.transcript-paragraph__speaker")) {
        speaker = sibling
        return speaker
      }
      sibling = sibling.previousElementSibling
    }
  }

  play(e) {
    if (this.isTextSelected()) return

    const timecode = e.target.value || e.currentTarget.getAttribute(TranscriptVideo.attributeStartTime)
    this.videoTarget.currentTime = parseFloat(timecode.replace(",", "."))
    this.videoTarget.play()

    if (!isMobile() && !e.currentTarget.classList.contains("transcript-paragraph")) {
      this.scrollToElement(e.currentTarget)
    }
  }

  scrollToElement(e) {
    const timecode = parseFloat(e.getAttribute(TranscriptVideo.attributeStartTime))
    const element = this.getTargetElement(this.paragraphTargets, timecode)
    element?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  isTextSelected() {
    const selection = window.getSelection()
    return selection && selection.toString().length > 0
  }

  highlight(e) {
    if (this.speakerTargets.length === 1) {
      return this.speakerTargets[0].classList.add(HIGHLIGHTED_CLASS)
    }

    // Remove previous highlight
    this.paragraphTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))

    const speaker = this.getSpeaker(e.currentTarget)
    speaker && speaker.classList.add(HIGHLIGHTED_CLASS)
  }

  unhighlight() {
    this.speakerTargets.forEach((element) => element.classList.contains(HIGHLIGHTED_CLASS) && element.classList.remove(HIGHLIGHTED_CLASS))
  }

  scroll() {
    this.videoTarget.play()
    const handleTimeUpdate = () => {
      const paragraph = this.getTargetElement(this.paragraphTargets, this.videoTarget.currentTime)
      paragraph?.scrollIntoView({ behavior: "instant", block: "center" })

      const chapter = this.getTargetElement(this.chapterTargets, this.videoTarget.currentTime)
      chapter?.scrollIntoView({ behavior: "instant", block: "nearest" })
    }
    this.videoTarget.addEventListener("timeupdate", handleTimeUpdate, { once: true })
  }

  scrollToParagraph() {
    const hash = window.location.hash?.substring(1)
    if (!hash) return

    const elements = this.paragraphTargets.filter(element => element.getAttribute(TranscriptVideo.attributeStartTime) === hash)
    if (elements.length === 0) return

    // The same partial exists twice (one for mobile, one for desktop), because of layout differences
    const element = isMobile() ? elements[1] : elements[0]
    if (isMobile()) {
      window.scrollTo({ behavior: "smooth", top: element.getBoundingClientRect().top - (this.videoTarget.offsetHeight + this.videoTarget.getBoundingClientRect().top) })
    } else {
      element.scrollIntoView({ behavior: "smooth", block: "center" })
    }
    const paragraph = element.querySelector(".transcript-paragraph__content")
    paragraph.classList.add(TranscriptVideo.classHighlighted)
    setTimeout(() => {
      paragraph.classList.remove(TranscriptVideo.classHighlighted)
    }, 5000)
  }

  connect() {
    this.scrollToParagraph()
  }
}

class TranscriptSearch extends Controller {
  static values = { query: String, isPageList: Boolean }
  static classParagraph = ".transcript-paragraph__text"
  static classTitle = ".transcript-metadata__title"

  connect() {
    if (this.queryValue) {
      this.markSearch()
    }
  }

  markSearch() {
    const markClass = this.isPageListValue ? TranscriptSearch.classTitle : TranscriptSearch.classParagraph
    const marker = new Mark(markClass)
    const query = this.queryValue.trim()

    marker.mark(query, {
      separateWordSearch: false,
      className: HIGHLIGHTED_CLASS,
    })
  }
}

class TranscriptSearchNavigation extends Controller {
  static targets = ["paragraph", "previousButton", "nextButton"]

  connect() {
    if (isMobile()) return
    this.navPosition = 0
    this.scrollToElement()
  }

  next() {
    if (this.navPosition === this.paragraphTargets.length - 1) {
      return
    }
    this.navPosition++
    this.disableButton("next")
    this.scrollToElement()
  }

  previous() {
    if (this.navPosition === 0) {
      return
    }
    this.navPosition--
    this.disableButton("previous")
    this.scrollToElement()
  }

  // private methods

  disableButton(direction) {
    if (direction === "next" && this.navPosition === this.paragraphTargets.length - 1) {
      this.updateButtonState(this.nextButtonTarget, true)
    }
    if (direction === "previous" && this.navPosition === 0) {
      this.updateButtonState(this.previousButtonTarget, true)
    }
    if (this.navPosition > 0) {
      this.updateButtonState(this.previousButtonTarget, false)
    }
    if (this.navPosition < this.paragraphTargets.length - 1) {
      this.updateButtonState(this.nextButtonTarget, false)
    }
  }

  updateButtonState(button, condition) {
    button.disabled = condition
    if (condition) button.classList.add(DISABLED_CLASS)
    else button.classList.remove(DISABLED_CLASS)
  }

  scrollToElement() {
    if (this.paragraphTargets.length === 0) {
      if (window.scrollY !== 0) {
        window.scrollTo({ behavior: "smooth", top: 0 })
      }
      return
    }
    this.paragraphTargets[this.navPosition].scrollIntoView({ behavior: "smooth", block: "center" })
    this.paragraphTargets.forEach((element) => element.classList.remove(SCROLLING_INTO_CLASS))
    this.paragraphTargets[this.navPosition].classList.add(SCROLLING_INTO_CLASS)
  }
}

export { TranscriptSearch, TranscriptSearchNavigation, TranscriptVideo }
