import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"
import { useDebounce } from "stimulus-use"

class BillListSearch extends Controller {
  static targets = ["bill", "query", "sidenav"]
  static debounces = ["markSearch"]

  initialize() {
    useDebounce(this, { wait: 50 })
  }

  billTargetConnected() {
    this.markSearch()
  }

  markSearch() {
    console.log("markSearch")
    const marker = new Mark("#searched-bills")
    const query = this.queryTarget.value.trim()

    marker.unmark()
    if (query) {
      marker.mark(query, {
        separateWordSearch: true,
        className: ".search-highlight",
        filter(_node, term) {
          return term.length > 2
        },
      })
    }
    this.sidenavTarget.classList.toggle("-hidden", query)
  }
}

class BillListNav extends Controller {
  static targets = ["contents", "entry", "section"]
  static classHidden = "-hidden"
  static classActive = "-active"

  observerCallback = (intersectionEntries) => {
    intersectionEntries.forEach(intersectionEntry => {
      if (intersectionEntry.isIntersecting) {
        for (const entry of document.querySelectorAll(".sidenav__link")) {
          if (entry.getAttribute("id") === intersectionEntry.target.getAttribute("data-bill-list-nav-section-value")) {
            entry.classList.add(BillListNav.classActive)
          } else {
            entry.classList.remove(BillListNav.classActive)
          }
        }
      }
    })
  }

  observer = new IntersectionObserver(this.observerCallback, { rootMargin: "-30% 0px -70% 0px" })

  sectionTargetConnected(elt) {
    this.observer.observe(elt)
  }

  contentsTargetConnected() {
    this.entryTargets.forEach(elt => {
      if (document.querySelector(elt.getAttribute("href"))) {
        elt.classList.remove(BillListNav.classHidden)
      } else if (elt.getAttribute("data-bill-list-nav-optional-value") === "true") {
        elt.classList.add(BillListNav.classHidden)
      }
    })
  }
}

export { BillListNav, BillListSearch }
