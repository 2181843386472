import { isMobile } from "../utils/breakpoints.js"
import { log } from "../utils/logging"

export class Intercom {
  isEnabled = false

  // --- Public methods

  init(enabled, appId, env) {
    this.isEnabled = enabled && appId
    if (!this.isEnabled || this.isInit()) {
      return
    }

    // Intercom settings https://app.intercom.com/a/apps/le2h7qzd/settings/installation
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: appId,
      name: env.userName || undefined,
      user_id: env.userId || undefined,
      email: env.userEmail || undefined,
      created_at: env.userCreatedAt || undefined,
      user_hash: env.userHash || undefined,
      hide_default_launcher: isMobile(),
      custom_launcher_selector: ".intercom-launcher",
    };

    // Load script
    /* eslint-disable */
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/le2h7qzd';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()

    log("Intercom init OK")

    return true
  }

  isInit() {
    return !!window.intercomSettings
  }
}
