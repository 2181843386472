import { Controller } from "@hotwired/stimulus"

class WatchGroup extends Controller {
  static targets = ["editField", "header", "input", "name", "newName", "saveButton", "form", "formAlert"]

  static classOpen = "-open"
  static classHidden = "-hidden"

  connect() {
    this.element.addEventListener("htmx:afterSwap", () => {
      this.updateFormDisplay()
    })
    this.updateFormDisplay()
  }

  _choiceLabels() {
    const labels = this.element.querySelectorAll(".radio__label")
    return labels || []
  }

  _hideParams() {
    this._choiceLabels().forEach(label => {
      label.firstChild.setAttribute("disabled", "")
    })

    this.element.querySelector(".watchgroup-param")?.classList.add(WatchGroup.classHidden)
  }

  _showParams() {
    this._choiceLabels().forEach(element => {
      element.firstChild.removeAttribute("disabled")
    })
    this.element.querySelector(".watchgroup-param")?.classList.remove(WatchGroup.classHidden)
  }

  _hasCheckedDocumentTarget() {
    return Array.from(this.formTarget.elements).some((element) => (
      element.name === "publication_targets" && element.checked
    ))
  }

  updateFormDisplay() {
    const keywords = this.element.querySelectorAll(".chip")
    let showAlert = false

    if (keywords.length > 0) {
      this._showParams()
    } else {
      this._hideParams()
      showAlert = true
    }

    if (!this.hasFormAlertTarget) return
    showAlert |= !this._hasCheckedDocumentTarget()

    if (showAlert) {
      this.formAlertTarget.classList.remove(WatchGroup.classHidden)
      this.saveButtonTarget.disabled = true
    } else {
      this.formAlertTarget.classList.add(WatchGroup.classHidden)
      this.saveButtonTarget.disabled = false
    }
  }

  edit() {
    this.headerTarget.classList.add(WatchGroup.classHidden)
    this.editFieldTarget.classList.remove(WatchGroup.classHidden)

    const input = this.inputTarget
    const end = input.value.length
    input.setSelectionRange(end, end)
    input.focus()
  }

  cancel() {
    this.hide()
  }

  done() {
    const newValue = this.inputTarget.value

    if (newValue !== this.nameTarget.innerHTML) {
      this.saveButtonTarget.disabled = false
    }

    this.nameTarget.innerHTML = newValue
    this.inputTarget.value = newValue
    this.newNameTarget.value = newValue
    this.hide()
  }

  hide() {
    this.editFieldTarget.classList.add(WatchGroup.classHidden)
    this.headerTarget.classList.remove(WatchGroup.classHidden)
  }
}

export { WatchGroup }
